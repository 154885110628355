// src/components/Step2UploadAssets.js

import { UploadOutlined } from '@ant-design/icons';
import { Button, Image, List, message, Upload } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormContext } from '../context/FormContext';

const Step2UploadAssets = () => {
  const { formData, setUploadedAssets } = useContext(FormContext);
  
  // Initialize fileList with Ant Design's UploadFile objects, including size
  const [fileList, setFileList] = useState(
    formData.uploadedAssets.map((file, index) => ({
      uid: `-1-${index}`, // Unique identifier
      name: file.name,
      status: 'done',
      url: URL.createObjectURL(file), // Create preview URL
      size: file.size, // Include size in bytes
      originFileObj: file, // Keep reference to the File object
    }))
  );

  // Cleanup: Revoke object URLs when component unmounts or fileList changes
  useEffect(() => {
    return () => {
      fileList.forEach(file => {
        if (file.url) {
          URL.revokeObjectURL(file.url);
        }
      });
    };
  }, [fileList]);

  const handleUpload = ({ file, fileList: newFileList }) => {
    // Validate file type
    const isValidType =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/gif' ||
      file.type === 'image/bmp';

    if (!isValidType) {
      message.error(`${file.name} is not a supported file type.`);
      return;
    }

    // Validate file size (<5MB)
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error(`${file.name} is larger than 5MB.`);
      return;
    }

    // Map the new fileList to include preview URLs and size
    const processedFileList = newFileList.map(f => {
      if (f.originFileObj) {
        return {
          uid: f.uid,
          name: f.name,
          status: 'done',
          url: URL.createObjectURL(f.originFileObj),
          size: f.originFileObj.size, // Include size in bytes
          originFileObj: f.originFileObj,
        };
      }
      return f;
    });

    setFileList(processedFileList);
    // Extract File objects to store in uploadedAssets
    const uploadedFiles = processedFileList.map(f => f.originFileObj);
    setUploadedAssets(uploadedFiles);
  };

  const handleRemove = (file) => {
    // Revoke the object URL to free memory
    if (file.url) {
      URL.revokeObjectURL(file.url);
    }

    const newList = fileList.filter(f => f.uid !== file.uid);
    setFileList(newList);
    // Update uploadedAssets
    const uploadedFiles = newList.map(f => f.originFileObj);
    setUploadedAssets(uploadedFiles);
  };

  return (
    <div>
      <Upload
        multiple
        beforeUpload={() => false} // Prevent automatic upload
        onChange={handleUpload}
        fileList={fileList}
        onRemove={handleRemove}
        listType="picture"
      >
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
      <List
        itemLayout="horizontal"
        dataSource={fileList}
        renderItem={(file) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                file.url ? (
                  <Image width={50} src={file.url} alt={file.name} />
                ) : (
                  <img src="/images/pdf-icon.png" alt="PDF Icon" width={50} />
                )
              }
              title={file.name}
              description={`${(file.size / 1024).toFixed(2)} KB`} 
            />
          </List.Item>
        )}
        style={{ marginTop: 16 }}
      />
    </div>
  );
};

export default Step2UploadAssets;
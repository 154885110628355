// src/components/MultiStepForm.js

import React, { useState, useContext } from "react";
import { Steps, Button, message, Spin } from "antd";
import Step1SelectTemplate from "./Step1SelectTemplate";
import Step2UploadAssets from "./Step2UploadAssets";
import Step3BusinessInfo from "./Step3BusinessInfo";
import Step4SelectLocations from "./Step4SelectLocations"; // New Step
import Step5PreviewSubmit from "./Step5PreviewSubmit"; // Renamed Step
import { FormContext } from "../context/FormContext";
import axios from "axios";

const { Step } = Steps;

const MultiStepForm = () => {
  const [current, setCurrent] = useState(0);
  const { formData, resetForm } = useContext(FormContext);
  const [loading, setLoading] = useState(false);
  
  const steps = [
    {
      title: "Select Truck Template",
      content: <Step1SelectTemplate />,
    },
    {
      title: "Upload Assets",
      content: <Step2UploadAssets />,
    },
    {
      title: "Business Information",
      content: <Step3BusinessInfo />,
    },
    {
      title: "Select Locations", // New Step Title
      content: <Step4SelectLocations />,
    },
    {
      title: "Preview & Submit",
      content: <Step5PreviewSubmit />,
    },
  ];

  const next = () => {
    if (current === 0 && !formData.selectedTruck) {
      message.error("Please select a truck template before proceeding.");
      return;
    }
    if (current === 1 && formData.uploadedAssets.length < 3) {
      message.error("Please upload at least 3 images to proceed.");
      return;
    }
    if (current === 2) {
      const {
        businessName,
        description,
        email,
        mobileNumber,
        campaignDetails,
      } = formData.businessInfo;
      if (
        !businessName ||
        !description ||
        !email ||
        !mobileNumber ||
        !campaignDetails
      ) {
        message.error(
          "Please fill out all required business information fields."
        );
        return;
      }
    }
    if (current === 3) { // New Validation for Locations
      if (!formData.selectedLocations || formData.selectedLocations.length < 1) {
        message.error("Please select at least one advertising location.");
        return;
      }
    }
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const submissionData = new FormData();
      submissionData.append("templateId", formData.selectedTruck.id);
      submissionData.append("configuration", formData.selectedTruck.configuration);
      submissionData.append("businessName", formData.businessInfo.businessName);
      submissionData.append("description", formData.businessInfo.description);
      submissionData.append("email", formData.businessInfo.email);
      submissionData.append("mobileNumber", formData.businessInfo.mobileNumber);
      submissionData.append(
        "campaignDetails",
        formData.businessInfo.campaignDetails
      );
      submissionData.append(
        "companyWebsite",
        formData.businessInfo.companyWebsite || ""
      );
      submissionData.append(
        "socialMediaPages",
        JSON.stringify(formData.businessInfo.socialMediaPages)
      );

      // Append locations
      submissionData.append(
        "selectedLocations",
        JSON.stringify(formData.selectedLocations)
      );

      formData.uploadedAssets.forEach((file) => {
        submissionData.append("assets", file);
      });

      const response = await axios.post(
        "https://zaprod.qiktruck.co.za/busylocation/submit-campaign",
        submissionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        message.success("Design submitted successfully!");
        resetForm();
        setCurrent(0);
      } else {
        message.error("Submission failed. Please try again.");
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred during submission.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Steps current={current} responsive>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content" style={{ marginTop: 24 }}>
        {steps[current].content}
      </div>
      {loading && <Spin tip="Submitting your campaign..." />}
      <div className="steps-action" style={{ marginTop: 24 }}>
        {current > 0 && (
          <Button
            style={{ margin: "0 8px" }}
            onClick={() => prev()}
            disabled={loading}
          >
            Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()} disabled={loading}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={handleSubmit} disabled={loading}>
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default MultiStepForm;
// src/components/Step5PreviewSubmit.js

import React, { useContext } from "react";
import { Form, Descriptions, List, Image } from "antd";
import { FormContext } from "../context/FormContext";

const Step5PreviewSubmit = () => {
  const { formData } = useContext(FormContext);

  return (
    <Form layout="vertical">
      <Descriptions title="Review Your Campaign" bordered column={1}>
        <Descriptions.Item label="Truck Template">
          {formData.selectedTruck ? formData.selectedTruck.configuration : "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Uploaded Assets">
          <List
            dataSource={formData.uploadedAssets}
            renderItem={(file, index) => (
              <List.Item key={index}>
                <Image
                  width={100}
                  src={URL.createObjectURL(file)}
                  alt={`Asset ${index + 1}`}
                />
              </List.Item>
            )}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Business Information">
          <p><strong>Business Name:</strong> {formData.businessInfo.businessName}</p>
          <p><strong>Description:</strong> {formData.businessInfo.description}</p>
          <p><strong>Email:</strong> {formData.businessInfo.email}</p>
          <p><strong>Mobile Number:</strong> {formData.businessInfo.mobileNumber}</p>
          <p><strong>Campaign Details:</strong> {formData.businessInfo.campaignDetails}</p>
          <p><strong>Company Website:</strong> {formData.businessInfo.companyWebsite || "N/A"}</p>
          <p>
            <strong>Social Media Pages:</strong>{" "}
            {formData.businessInfo.socialMediaPages
              ? Object.entries(formData.businessInfo.socialMediaPages)
                  .map(([platform, url]) => `${platform}: ${url}`)
                  .join(", ")
              : "N/A"}
          </p>
        </Descriptions.Item>
        <Descriptions.Item label="Selected Locations">
          <List
            dataSource={formData.selectedLocations}
            renderItem={(location, index) => (
              <List.Item key={index}>
                <p>{location.address}</p>
              </List.Item>
            )}
          />
        </Descriptions.Item>
      </Descriptions>
    </Form>
  );
};

export default Step5PreviewSubmit;
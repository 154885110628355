// src/components/Step3BusinessInfo.js

import React, { useContext, useEffect } from 'react';
import { Form, Input, Button, Space, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FormContext } from '../context/FormContext';

const { TextArea } = Input;
const { Option } = Select;

const socialMediaPlatforms = [
  'Facebook',
  'Twitter',
  'Instagram',
  'LinkedIn',
  'YouTube',
  'Pinterest',
  'TikTok',
  'Snapchat',
  'Reddit',
  'Tumblr',
];

const Step3BusinessInfo = () => {
  const { formData, setBusinessInfo } = useContext(FormContext);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(formData.businessInfo);
  }, [formData.businessInfo, form]);

  const onValuesChange = (changedValues, allValues) => {
    setBusinessInfo(allValues);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="business_info"
      onValuesChange={onValuesChange}
    >
      <Form.Item
        label="Business Name"
        name="businessName"
        rules={[{ required: true, message: 'Please enter your business name.' }]}
      >
        <Input placeholder="Enter your business name" />
      </Form.Item>

      <Form.Item
        label="Business Description"
        name="description"
        rules={[{ required: true, message: 'Please enter a description.' }]}
      >
        <TextArea rows={4} placeholder="Describe your business or service" />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'Please enter your email address.' },
          { type: 'email', message: 'Please enter a valid email address.' },
        ]}
      >
        <Input placeholder="Enter your email address" />
      </Form.Item>

      <Form.Item
        label="Mobile Number"
        name="mobileNumber"
        rules={[
          { required: true, message: 'Please enter your mobile number.' },
        ]}
      >
        <Input placeholder="Enter your mobile number" />
      </Form.Item>

      <Form.Item
        label="Campaign Details"
        name="campaignDetails"
        rules={[{ required: true, message: 'Please provide campaign details.' }]}
      >
        <TextArea rows={4} placeholder="Describe your advertising campaign" />
      </Form.Item>

      <Form.Item
        label="Company Website"
        name="companyWebsite"
        rules={[
          {
            type: 'text',
            message: 'Please enter a valid URL.',
          },
        ]}
      >
        <Input placeholder="Enter your company website (optional)" />
      </Form.Item>

      <Form.List
        name="socialMediaPages"
        rules={[
          {
            validator: async (_, socialMediaPages) => {
              if (socialMediaPages && socialMediaPages.length > 5) {
                return Promise.reject(new Error('You can add up to 5 social media pages.'));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            <Form.Item label="Social Media Pages (Optional)">
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '100%' }}
                icon={<PlusOutlined />}
                disabled={fields.length >= 5}
              >
                Add Social Media Page
              </Button>
            </Form.Item>
            {fields.map((field, index) => (
              <Space
                key={field.key}
                align="baseline"
                style={{ display: 'flex', marginBottom: 8 }}
              >
                <Form.Item
                  {...field}
                  name={[field.name, 'platform']}
                  fieldKey={[field.fieldKey, 'platform']}
                  rules={[{ required: true, message: 'Please select a platform.' }]}
                >
                  <Select placeholder="Select Platform" style={{ width: 200 }}>
                    {socialMediaPlatforms.map((platform) => (
                      <Option key={platform} value={platform}>
                        {platform}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...field}
                  name={[field.name, 'url']}
                  fieldKey={[field.fieldKey, 'url']}
                  rules={[
                    { required: true, message: 'Please enter the username.' },
                    { type: 'text', message: 'Please enter a valid username.' },
                  ]}
                >
                  <Input placeholder="Enter URL" style={{ width: 300 }} />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}
            <Form.ErrorList errors={errors} />
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default Step3BusinessInfo;
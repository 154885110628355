// src/components/Step1SelectTemplate.js

import React, { useEffect, useState, useContext, useRef } from "react";
import { Card, Row, Col, Spin, message, Tag, Modal, Button } from "antd";
import axios from "axios";
import { FormContext } from "../context/FormContext";
import { EyeOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Carousel } from "antd";

const { Meta } = Card;

const Step1SelectTemplate = () => {
  const [trucks, setTrucks] = useState([]);
  const [loading, setLoading] = useState(false);
  const { formData, setSelectedTruck } = useContext(FormContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDesigns, setCurrentDesigns] = useState([]);
  const carouselRef = useRef(null);

  useEffect(() => {
    const fetchTrucks = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://zaprod.qiktruck.co.za/busylocation/design-templates"
        );
        setTrucks(response.data.templates);
      } catch (error) {
        console.error(error);
        message.error("Failed to fetch truck templates.");
      } finally {
        setLoading(false);
      }
    };

    fetchTrucks();
  }, []);

  const handleSelect = (truck) => {
    setSelectedTruck(truck);
  };

  const showModal = (designs) => {
    setCurrentDesigns(designs);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setCurrentDesigns([]);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentDesigns([]);
  };

  const nextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const prevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  return (
    <div>
      {loading ? (
        <Spin tip="Loading truck templates..." />
      ) : (
        <Row gutter={[16, 16]}>
          {trucks.map((truck) => (
            <Col xs={24} sm={12} md={8} lg={6} key={truck.id}>
              <Card
                hoverable
                cover={<img alt={truck.configuration} src={truck.imageurl} />}
                onClick={() => handleSelect(truck)}
                bordered={formData.selectedTruck?.id === truck.id}
                style={{
                  borderColor:
                    formData.selectedTruck?.id === truck.id
                      ? "#1890ff"
                      : undefined,
                }}
                actions={[
                  <EyeOutlined
                    key="preview"
                    onClick={(e) => {
                      e.stopPropagation();
                      showModal(truck.exampleDesigns);
                    }}
                    title="View Example Designs"
                  />,
                ]}
              >
                <Meta
                  title={truck.configuration.replace(/([A-Z])/g, " $1").trim()}
                  description={`Price: $${truck.price}`}
                />
                {formData.selectedTruck?.id === truck.id && (
                  <Tag color="blue" style={{ marginTop: 8 }}>
                    Selected
                  </Tag>
                )}
              </Card>
            </Col>
          ))}
        </Row>
      )}

      <Modal
        title="Example Designs"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="prev" onClick={prevSlide} icon={<LeftOutlined />}>
            Previous
          </Button>,
          <Button key="next" onClick={nextSlide} icon={<RightOutlined />}>
            Next
          </Button>,
          <Button key="close" type="primary" onClick={handleOk}>
            Close
          </Button>,
        ]}
        width={800}
      >
        <Carousel ref={carouselRef} autoplay>
          {currentDesigns.map((designUrl, index) => (
            <div key={index}>
              <img
                src={designUrl}
                alt={`Example Design ${index + 1}`}
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "600px",
                  objectFit: "contain",
                }}
              />
            </div>
          ))}
        </Carousel>
      </Modal>
    </div>
  );
};

export default Step1SelectTemplate;
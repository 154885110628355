// src/App.js

import React from "react";
import { FormProvider } from "./context/FormContext";
import MultiStepForm from "./components/MultiStepForm";
import "./styles/styles.scss";
import { Image, Typography, Button, Row, Col, Card } from "antd";

const { Title, Paragraph } = Typography;
const { Meta } = Card;

const App = () => {
  return (
    <FormProvider>
      <Image src="/busylocation.svg" alt="BusyLocation Logo" preview={false} />

      {/* Hero Section */}
      <div className="hero-section">
        <Row justify="center" align="middle" style={{ padding: "0 20px" }}>
          <Col xs={24} md={12}>
            <Title level={1}>Amplify Your Brand with Vehicle Advertising</Title>

            <Paragraph>
              BusyLocation offers premier vehicle advertising solutions designed
              to boost your business presence on the streets. Our expert team
              crafts unique and vibrant vehicle wraps that capture attention and
              drive your brand forward.
            </Paragraph>
            <Button type="primary" size="large" href="#multi-step-form">
              Get Started Today
            </Button>
          </Col>
          <Col xs={24} md={12}>
            <Image
              src="/truck-showcase-2.jpg"
              alt="Custom Vehicle Design Showcase"
              preview={false}
            />
          </Col>
        </Row>
      </div>

      {/* Features Section */}
      <div
        className="features-section"
        style={{ padding: "50px 20px", backgroundColor: "#f0f2f5" }}
      >
        <Title level={2} style={{ textAlign: "center", marginBottom: "40px" }}>
          Why Choose BusyLocation for Vehicle Advertising?
        </Title>
        <Row justify="center" gutter={[16, 16]}>
          <Col xs={24} md={6}>
            <Card hoverable>
              <Image src="/feature1.svg" alt="High Visibility" />
              <Meta
                title="High Visibility"
                description="Increase your brand's exposure with eye-catching vehicle wraps that stand out in any environment."
              />
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card hoverable>
              <Image src="/feature2.svg" alt="Custom Designs" />
              <Meta
                title="Custom Designs"
                description="Our creative team designs bespoke graphics tailored to your brand’s unique identity."
              />
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card hoverable>
              <Image src="/feature3.svg" alt="Durable Materials" />
              <Meta
                title="Durable Materials"
                description="We use high-quality, weather-resistant materials to ensure your ads remain vibrant and intact."
              />
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card hoverable>
              <Image src="/feature4.svg" alt="Nationwide Coverage" />
              <Meta
                title="Nationwide Coverage"
                description="Reach your target audience across various regions with our extensive fleet operating daily."
              />
            </Card>
          </Col>
        </Row>
      </div>

      {/* Fleet Advertising Section */}
      <div
        className="fleet-advertising-section"
        style={{ padding: "50px 20px", backgroundColor: "#ffffff" }}
      >
        <Title level={2} style={{ textAlign: "center", marginBottom: "40px" }}>
          Transform Your Business with Mobile Billboards
        </Title>
        <Row justify="center" gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Image
              src="/fleet-map.svg"
              alt="Fleet Coverage Map"
              preview={false}
            />
          </Col>
          <Col xs={24} md={12}>
            <Paragraph>
              BusyLocation provides innovative mobile marketing solutions by
              turning our fleet into moving billboards. Whether you're a small
              business or a large corporation, our vehicle advertising services
              are designed to maximize your brand's visibility and reach.
            </Paragraph>
            <Paragraph>
              Partner with us to benefit from:
              <ul>
                <li>Strategic Ad Placement</li>
                <li>Flexible Advertising Packages</li>
                <li>Expert Design and Installation</li>
                <li>Comprehensive Campaign Analytics</li>
              </ul>
            </Paragraph>
            <Button type="primary" size="large" href="#multi-step-form">
              Explore Advertising Options
            </Button>
          </Col>
        </Row>
      </div>

      {/* Testimonials Section */}
      <div
        className="testimonials-section"
        style={{ padding: "50px 20px", backgroundColor: "#ffffff" }}
      >
        <Title level={2} style={{ textAlign: "center", marginBottom: "40px" }}>
          What Our Clients Say
        </Title>
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} md={8}>
            <Card>
              <Paragraph>
                "Partnering with BusyLocation has significantly increased our
                brand visibility. Their vehicle advertising ensures our message
                reaches thousands daily!"
              </Paragraph>
              <Meta title="- John Doe, Acme Corp" />
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card>
              <Paragraph>
                "Professional service and stunning vehicle wraps. Highly
                recommend BusyLocation for any advertising needs."
              </Paragraph>
              <Meta title="- Jane Smith, QuickDeliver" />
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card>
              <Paragraph>
                "Our branded vehicles now stand out on the road, attracting more
                customers and boosting our brand presence."
              </Paragraph>
              <Meta title="- Mike Johnson, FastMove" />
            </Card>
          </Col>
          {/* Additional Testimonials as Needed */}
        </Row>
      </div>

      {/* SEO Content Section */}
      <div
        className="seo-content-section"
        style={{ padding: "50px 20px", backgroundColor: "#f0f2f5" }}
      >
        <Title level={2} style={{ textAlign: "center", marginBottom: "40px" }}>
          Drive Your Brand Forward with Professional Vehicle Advertising
        </Title>
        <Paragraph>
          In today's competitive market, standing out is crucial for business
          success. BusyLocation specializes in transforming our fleet into
          dynamic mobile billboards across South Africa. Our strategic vehicle
          advertising solutions ensure your brand gains maximum exposure and
          recognition on the streets.
        </Paragraph>
        <Paragraph>
          Our comprehensive services include full and partial vehicle wraps,
          custom decals, and strategically placed branding elements tailored to
          your specific needs. We utilize high-quality, weather-resistant
          materials that maintain the vibrancy and integrity of your designs,
          regardless of environmental conditions. With BusyLocation, you can
          expect seamless campaign management, timely execution, and exceptional
          customer service.
        </Paragraph>
        <Paragraph>
          Partner with BusyLocation today to take advantage of our innovative
          mobile marketing solutions. Enhance your brand's presence, attract new
          customers, and achieve your marketing goals with our effective and
          far-reaching vehicle advertising strategies.
        </Paragraph>
      </div>

      {/* Call-to-Action (CTA) Section */}
      <div
        className="cta-section"
        style={{
          padding: "50px 20px",
          textAlign: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Title level={2}>
          Ready to Amplify Your Brand with Vehicle Advertising?
        </Title>
        <Paragraph>
          Partner with BusyLocation to advertise your business on our expertly
          wrapped vehicles. Our streamlined multi-step form makes it easy to
          select your advertising package, upload your assets, and launch your
          campaign. Join the multitude of businesses that have elevated their
          brand visibility with BusyLocation.
        </Paragraph>
        <Button type="primary" size="large" href="#multi-step-form">
          Start Your Advertising Campaign
        </Button>
      </div>

      {/* Multi-Step Form Section */}
      <div
        id="multi-step-form"
        className="form-section"
        style={{ padding: "50px 20px", backgroundColor: "#f9f9f9" }}
      >
        <Title level={2} style={{ textAlign: "center", marginBottom: "40px" }}>
          Customize Your Advertising Campaign
        </Title>
        <Paragraph style={{ textAlign: "center", marginBottom: "40px" }}>
          Follow our simple multi-step form to select your advertising package,
          upload your assets, provide your business information, and preview
          your custom campaign before submission.
        </Paragraph>
        <MultiStepForm />
      </div>

      {/* Footer Section */}
      <div className="footer-section">
        <Title level={4} className="footer-title">
          Contact Us
        </Title>
        <Paragraph className="contact-info">
          <a href="mailto:info@busylocation.com">info@busylocation.com</a> |
          <a href="tel:+1234567890"> +1 (234) 567-890</a>
        </Paragraph>
        <Paragraph className="contact-address">
          123 Business Street, Suite 456, City, Country
        </Paragraph>
        <Paragraph className="additional-info">
          <strong>Fleet Advertising:</strong> Leverage our extensive fleet to
          maximize your brand's reach across South Africa.
        </Paragraph>
        <div className="social-icons">
          <a
            href="https://facebook.com/busylocation"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src="/facebook-icon.svg" alt="Facebook" preview={false} />
          </a>
          <a
            href="https://twitter.com/busylocation"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src="/twitter-icon.svg" alt="Twitter" preview={false} />
          </a>
          <a
            href="https://instagram.com/busylocation"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src="/instagram-icon.svg" alt="Instagram" preview={false} />
          </a>
          <a
            href="https://linkedin.com/company/busylocation"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src="/linkedin-icon.svg" alt="LinkedIn" preview={false} />
          </a>
        </div>
        <Paragraph className="footer-copy">
          &copy; {new Date().getFullYear()} BusyLocation. All rights reserved.
        </Paragraph>
      </div>
    </FormProvider>
  );
};

export default App;

// src/context/FormContext.js

import React, { createContext, useState } from "react";

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    selectedTruck: null,
    uploadedAssets: [],
    businessInfo: {
      businessName: "",
      description: "",
      email: "",
      mobileNumber: "",
      campaignDetails: "",
      companyWebsite: "",
      socialMediaPages: [],
    },
    selectedLocations: [],
  });

  const resetForm = () => {
    setFormData({
      selectedTruck: null,
      uploadedAssets: [],
      businessInfo: {
        businessName: "",
        description: "",
        email: "",
        mobileNumber: "",
        campaignDetails: "",
        companyWebsite: "",
        socialMediaPages: [],
      },
      selectedLocations: [],
    });
  };

  // Helper function to set selected truck
  const setSelectedTruck = (truck) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedTruck: truck,
    }));
  };

  // Similarly, you can define other helper functions if needed
  const setUploadedAssets = (assets) => {
    setFormData((prevData) => ({
      ...prevData,
      uploadedAssets: assets,
    }));
  };

  const setBusinessInfo = (info) => {
    setFormData((prevData) => ({
      ...prevData,
      businessInfo: {
        ...prevData.businessInfo,
        ...info,
      },
    }));
  };

  const setSelectedLocations = (locations) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedLocations: locations,
    }));
  };

  return (
    <FormContext.Provider
      value={{
        formData,
        setFormData,
        resetForm,
        setSelectedTruck, // Provide helper function
        setUploadedAssets,
        setBusinessInfo,
        setSelectedLocations,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
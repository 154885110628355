// src/components/Step4SelectLocations.js

import React, { useState, useContext } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Form, Input, Button, List, message } from "antd";
import { FormContext } from "../context/FormContext";

const Step4SelectLocations = () => {
  const { formData, setFormData } = useContext(FormContext);
  const [address, setAddress] = useState("");
  const [addresses, setAddresses] = useState(formData.selectedLocations || []);

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      const newLocation = {
        address: value,
        latitude: latLng.lat,
        longitude: latLng.lng,
      };
      setAddresses([...addresses, newLocation]);
      setAddress("");
    } catch (error) {
      console.error("Error selecting address:", error);
      message.error("Failed to add location. Please try again.");
    }
  };

  const handleRemove = (index) => {
    const updatedAddresses = addresses.filter((_, i) => i !== index);
    setAddresses(updatedAddresses);
  };

  // Update context whenever addresses change
  React.useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      selectedLocations: addresses,
    }));
  }, [addresses, setFormData]);

  return (
    <Form layout="vertical">
      <Form.Item
        label="Select Advertising Locations"
        required
        tooltip="Choose at least one central location for your advertising campaign."
      >
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
          debounce={300}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: "Search Places ...",
                  className: "location-search-input",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      key={index}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          onClick={() => {
            if (addresses.length < 1) {
              message.error("Please select at least one location.");
            }
          }}
          disabled={addresses.length < 1}
        >
          Confirm Locations
        </Button>
      </Form.Item>

      <Form.Item label="Selected Locations">
        <List
          bordered
          dataSource={addresses}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  danger
                  onClick={() => handleRemove(index)}
                >
                  Remove
                </Button>,
              ]}
            >
              {item.address}
            </List.Item>
          )}
        />
      </Form.Item>
    </Form>
  );
};

export default Step4SelectLocations;